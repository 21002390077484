<template>
  <b-overlay :show="isProcessing">
    <vue-good-table
      :columns="columnsForCurriculumPageTable"
      :rows="curriculums"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span
            class="text-nowrap d-flex flex-column"
          ><span class="mb-1">{{ props.row.name }}
             <span v-if="props.row.is_master"
                   class="text-info"
             >
               (Original)
             </span>
           </span>

            <small>
              {{ $t("labels.version") }} - {{ props.row.version }}</small>
          </span>
        </span>
        <span
          v-if="props.column.field === 'color_scheme'"
          class="text-nowrap"
        >
          <span class="text-nowrap d-flex flex-column">
            <span class="mb-1">{{ props.row.color_scheme }}</span>
            <span>
              <router-link
                v-if="props.row.lesson"
                :to="`/${AUTH_USER().usertype}/problem?lesson_id=${props.row.lesson.id}`"
              >{{ props.row.lesson.name }}</router-link>
            &nbsp;
              <feather-icon
                class="text-warning"
                role="button"
                icon="InfoIcon"
                @click="lessonIdToShowDetail = props.row.lesson.id"
              />
            </span>
          </span>
          <span v-if="props.row.vocab_lesson"
                class="text-nowrap d-flex flex-column mt-3"
          >
            <span class="mb-1">Vocab Lesson</span>
            <span>
              <router-link
                v-if="props.row.vocab_lesson"
                :to="`/${AUTH_USER().usertype}/problem?lesson_id=${props.row.vocab_lesson.id}`"
              >{{ props.row.vocab_lesson.name }}</router-link>
            &nbsp;
              <feather-icon
                class="text-warning"
                role="button"
                icon="InfoIcon"
                @click="lessonIdToShowDetail = props.row.vocab_lesson.id"
              />
            </span>
          </span>
        </span>
        <span
          v-if="props.column.field === 'pages'"
          class="text-nowrap"
        >
          <b-table
            v-if="props.row.pages.length > 0"
            responsive="sm"
            :fields="[{ key: 'title', label:$t('lesson-pages-module.title')} , {key: 'actions', label: $t('lessonPage-module.lesson-table.action')}]"
            :items="props.row.pages"
          >
            <template #cell(actions)="data">
              <b-button
                class="btn btn-icon mr-1"
                size="sm"
                variant="outline-primary"
                @click="$emit('openCurriculumPage', props.row.id, data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                class="btn btn-icon mr-1"
                variant="outline-primary"
                size="sm"
                @click="$emit('editCurriculumPage', data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                class="btn btn-icon mr-1"
                variant="outline-danger"
                size="sm"
                @click="$emit('deleteCurriculumPage', data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </span>
        <span
          v-else-if="props.column.field === 'action'"
          class="d-flex"
        >
          <span>
            <b-button
              :disabled="isProcessing"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="$emit('editCurriculum', props.row)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
          <span>
            <b-button
              v-b-tooltip.hover.top="`Generate audio for whole page`"
              :disabled="props.row.isProcessingAudio"
              variant="outline-secondary"
              class="btn-icon mr-1"
              @click="$emit('updateCbAudio', props.row)"
            >
              <b-spinner v-if="props.row.isProcessingAudio"
                         variant="success"
                         small
                         type="grow"
              />
              <feather-icon v-else
                            icon="RepeatIcon"
              />
            </b-button>
          </span>

          <span v-if="!checkLessonIsNormal( props.row.lesson)">
            <b-button v-if="props.row.queue_jobs && !props.row.queue_jobs.progress && !props.row.queue_jobs.exception_message"
                      v-b-tooltip.hover.top="`Building`"
                      variant="outline-primary"
                      class="btn-icon sm-mt-2 mr-1 mb-2"
            >
              <b-spinner variant="danger"
                         small
                         type="grow"
              />
            </b-button>
            <template v-else>
              <b-button
                v-if="props.row.version == '4'"
                v-b-tooltip.hover.top="
                  (props.row.queue_jobs && props.row.queue_jobs.exception_message) ? props.row.queue_jobs.exception_message
                  : $t('curriculum-pages-module.generate-ai-version')
                "
                :disabled="isProcessing || (isCloneProcessing && activeRowId === props.row.id)"
                :variant="(props.row.queue_jobs && props.row.queue_jobs.exception_message) ? 'danger': 'outline-warning'"
                class="btn-icon sm-mt-2 mr-1 mb-2"
                @click="
                  () => {
                    activeRowId = props.row.id;
                    $emit('cloneCurriculum', props.row.id, props.row.version == '4');
                  }
                "
              >
                <feather-icon
                  :icon="
                    isCloneProcessing && activeRowId === props.row.id
                      ? 'LoaderIcon'
                      : 'ShuffleIcon'
                  "
                />
              </b-button>
              <b-button
                v-b-tooltip.hover.top="
                  (props.row.queue_jobs && props.row.queue_jobs.exception_message) ? props.row.queue_jobs.exception_message
                  : $t('curriculum-pages-module.clone-curriculum')
                "
                :disabled="isProcessing || (isCloneProcessing && activeRowId === props.row.id)"
                :variant="(props.row.queue_jobs && props.row.queue_jobs.exception_message) ? 'danger': 'outline-success'"
                class="btn-icon sm-mt-2 mr-1 mb-2"
                @click="
                  () => {
                    activeRowId = props.row.id;
                    $emit('cloneCurriculum', props.row.id);
                  }
                "
              >
                <feather-icon
                  :icon="
                    isCloneProcessing && activeRowId === props.row.id
                      ? 'LoaderIcon'
                      : 'CopyIcon'
                  "
                />
              </b-button>
            </template>
          </span>
          <span>
            <b-button v-if="props.row.queue_jobs_lang_trans && !props.row.queue_jobs_lang_trans.progress && !props.row.queue_jobs_lang_trans.exception_message"
                      v-b-tooltip.hover.top="`Translating`"
                      variant="outline-primary"
                      class="btn-icon sm-mt-2 mr-1"
            >
              <b-spinner variant="danger"
                         small
                         type="grow"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover.top="
                (props.row.queue_jobs_lang_trans && props.row.queue_jobs_lang_trans.exception_message) ? props.row.queue_jobs_lang_trans.exception_message : $t('curriculum-pages-module.translate-curriculum')
              "
              :disabled="isProcessing"
              :variant="(props.row.queue_jobs_lang_trans && props.row.queue_jobs_lang_trans.exception_message) ? 'danger': 'outline-info'"
              class="btn-icon sm-mt-2 mr-1"
              @click="$emit('onTranslatePage', props.row)"
            >
              <feather-icon icon="CornerDownRightIcon" />
            </b-button>
          </span>
          <span>
            <b-button
              v-if="props.row.version == 4"
              v-b-tooltip.hover.top="
                $t('curriculum-pages-module.generate-problems')
              "
              :disabled="isProcessing"
              variant="outline-primary"
              class="btn-icon sm-mt-2 mr-1"
              @click="updateAllProblem(props.row)"
            >
              <feather-icon icon="BoxIcon" />
            </b-button>
          </span>

          <span>
            <b-button
              :disabled="isProcessing"
              variant="outline-danger"
              class="btn-icon sm-mt-2"
              @click="$emit('deleteCurriculum', props.row.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <b-form-select
              v-model="paginationData.perPage"
              :options="['3', '5', '10', '15', '20']"
              class="mr-1"
              @input="(d) => $emit('setPerPage', d)"
            />
            <span class="text-nowrap">
              {{ $t("of") }} {{ paginationData.totalItems }}
              {{ $t("curriculum-pages-module.curriculum-pages") }}
            </span>
          </div>
          <div>
            <b-pagination
              v-model="activePage"
              :total-rows="paginationData.totalItems"
              :per-page="paginationData.perPage"
              first-number
              last-number
              :jump-to="true"
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(d) => $emit('getCurriculum', d)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
            <div
              class="ml-2"
              style="margin-top: 4px"
            >
              Jump to <input
                v-model.lazy="activePage"
                style="width: 80px; display: inline"
                class="mx-1 form-control"
                type="number"
                placeholder="page-number"
              > of {{ number_format(paginationData.totalItems/ paginationData.perPage, 0) }}
            </div>
          </div>
          <lesson-detail-model
            :show="!!lessonIdToShowDetail"
            :lesson-id="lessonIdToShowDetail"
            @close="lessonIdToShowDetail=0"
          />
        </div>
      </template>

      <template slot="emptystate">
        <p class="text-center">
          {{ $t('messages.no-data-available') }}
        </p>

      </template>
    </vue-good-table>
  </b-overlay>
</template>

<script>
import {
  BButton, BPagination, BFormSelect, BTable, VBTooltip, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import LessonDetailModel from '../../components/LessonDetailModel.vue'

export default {
  components: {
    BButton,
    BPagination,
    BFormSelect,
    BTable,
    BOverlay,
    BSpinner,
    VueGoodTable,
    LessonDetailModel,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    curriculums: {
      type: Array,
      required: true,
    },
    propIsProcessing: {
      type: Boolean,
      default: false,
    },
    isCloneProcessing: {
      type: Boolean,
      default: false,
    },
    paginationData: {
      type: Object,
      required: true,
    },
    normalLessonId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lessonIdToShowDetail: 0,
      pageLength: 10,
      activePage: 1,
      columnsForCurriculumPageTable: [
        {
          label: this.$i18n.tc('curriculumPage-module.curriculum-table.name'),
          field: 'name',
          width: '100px',
        },
        {
          label: this.$i18n.tc('curriculumPage-module.curriculum-table.color_scheme_lesson'),
          field: 'color_scheme',
        },
        {
          label: this.$i18n.tc('curriculumPage-module.curriculum-table.pages'),
          field: 'pages',
        },
        {
          label: this.$i18n.tc('curriculumPage-module.curriculum-table.action'),
          field: 'action',
        },
      ],
      isProcessing: false,
      isGenerateProcessing: false,
      activeRowId: null,
    }
  },
  watch: {
    propIsProcessing(value) {
      this.isProcessing = value
    },
  },

  methods: {
    async updateAllProblem(item) {
      const { pages } = item
      this.isProcessing = true
      // eslint-disable-next-line no-restricted-syntax
      for (const page of pages) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const res = await useJwt.generateProblemsForCurriculum({
            page_id: page.id,
          })
          this.showSuccessMessage(res)
        } catch (error) {
          this.showError(`There is error while generating problem in ${page.title}`)
          this.showErrorMessage(error)
          break
        }
      }
      this.isProcessing = false
    },
    // generateProblems(cid) {
    //   this.isProcessing = true
    //   useJwt.generateProblemsForCurriculum({ cid }).then(res => {
    //     this.showSuccessMessage(res)
    //     this.$emit('updated')
    //   }).catch(error => {
    //     this.showErrorMessage(error)
    //   }).finally(() => {
    //     this.isProcessing = false
    //   })
    // },
    checkLessonIsNormal(lesson) {
      return Number(lesson?.type_id) === Number(this.normalLessonId)
    },
  },
}
</script>

<style>
</style>
