<template>
  <b-modal
    v-if="newItem"
    id="modal-create"
    ref="modal-create"
    v-model="isVisible"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    :title="$t('actions.' + mode)"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col
      md="12"
    >
      <!-- form -->
      <validation-observer
        ref="createItemForm"
      >
        <b-form
          class="auth-login-form"
          @submit.prevent="handleSubmit"
        >
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col
                  v-if="mode != 'update'"
                  md="2"
                >
                  <b-form-group
                    :label="$t('curriculum-pages-module.lesson-type')"
                  >
                    <b-form-select
                      v-model="lessonType"
                      :placeholder="$t('curriculum-pages-module.lesson-type')"
                      :options="lessonTypeOptions"
                      @change="getCourses"
                    />
                  </b-form-group>
                </b-col>
                <b-col :md="mode != 'update' ? 5: 6 ">
                  <b-form-group
                    :label="$t('curriculum-pages-module.course')"
                  >
                    <validation-provider
                      v-slot="{errors}"
                      :name="$t('curriculum-pages-module.course')"
                      rules="required"
                      vid="course_id"
                    >
                      <b-form-select
                        id="event-lesson"
                        v-model="newItem.course_id"
                        :options="courses"
                        :state="errors.length > 0 ? false : null"
                        @change="(id)=>getLessonGroups(id,true)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col :md="mode != 'update' ? 5: 6 ">
                  <b-form-group
                    :label="$t('curriculum-pages-module.lesson-group')"
                  >
                    <validation-provider
                      v-slot="{errors}"
                      :name="$t('curriculum-pages-module.lesson-group')"
                      rules="required"
                      vid="lesson_group_id"
                    >
                      <b-form-select
                        id="event-lesson"
                        v-model="newItem.lesson_group_id"
                        :options="lessonGroups"
                        :disabled="!newItem.course_id"
                        :state="errors.length > 0 ? false : null"
                        @change="(id)=>getLessons(id,true)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="12">
              <b-form-group
                :label="$t('curriculum-pages-module.lesson')"
                label-for="event-lesson"
              >
                <validation-provider
                  v-slot="{errors}"
                  :name="$t('curriculum-pages-module.lesson')"
                  rules="required"
                  vid="lesson_id"
                >

                  <b-form-select
                    id="event-lesson"
                    v-model="newItem.lesson_id"
                    :disabled="!newItem.lesson_group_id"
                    :options="lessons"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- name -->
            <b-col md="12">
              <b-form-group
                :label="$t('labels.name')"
                label-for="Name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.name')"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="item_name"
                    v-model="newItem.name"
                    name="item_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- name -->
            <!-- Subjects -->
            <b-col>
              <b-form-group
                :label="$t('standards.name.color_scheme')"
                label-for="Color scheme"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="color scheme"
                  vid="color_scheme"
                  rules="required"
                >
                  <b-form-select
                    id="color_scheme"
                    v-model="newItem.color_scheme"
                    :options="colorSchemes"
                    :state="errors.length > 0 ? false : null"
                    name="color_scheme"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <theme-preview :theme="newItem.color_scheme[newItem.color_scheme.length-1]" />
              <!-- Subjects -->
            </b-col>
            <b-col md="12">
              <b-form-group :label="$t('curriculum-pages-module.include-vocab')">
                <b-form-checkbox v-model="newItem.has_vocab_lesson"
                                 value="1"
                                 switch
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="handleSubmit"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t("actions." + mode) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormInput, BFormGroup, BFormSelect, BForm, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import ThemePreview from '@/views/common/components/ThemePreview.vue'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BFormSelect,
    BFormInput,
    BCol,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    ThemePreview,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    newItem: {
      type: [Object, null],
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    libraryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isProcessing: false,
      isVisible: false,
      courses: [],
      lessonGroups: [],
      lessons: [],
      colorSchemes: [
        { value: 'color_scheme_1', text: 'Theme 1', id: 1 },
        { value: 'color_scheme_2', text: 'Theme 2', id: 2 },
        { value: 'color_scheme_3', text: 'Theme 3', id: 3 },
        { value: 'color_scheme_4', text: 'Theme 4', id: 4 },
        { value: 'color_scheme_5', text: 'Theme 5', id: 5 },
        { value: 'color_scheme_6', text: 'Theme 6', id: 6 },
        { value: 'color_scheme_7', text: 'Theme 7', id: 7 },
        { value: 'color_scheme_8', text: 'Theme 8', id: 8 },
        { value: 'color_scheme_9', text: 'Theme 9', id: 9 },
      ],
      lessonTypeOptions: [
        {
          text: 'Four Level', value: 'four_level',
        },
        {
          text: 'Normal', value: 'normal',
        },
      ],
      lessonType: this.type,
    }
  },
  computed: {
    mode() {
      return this.newItem && this.newItem.id ? 'update' : 'create'
    },
  },
  watch: {
    show() {
      this.isVisible = this.show
      if (this.mode === 'update') {
        this.getLessonGroups(this.newItem.course_id)
        this.getLessons(this.newItem.lesson_group_id)
      }
    },
  },
  created() {
    this.getCourses()
    if (this.mode === 'update') {
      this.getLessonGroups(this.newItem.course_id, false)
      this.getLessons(this.newItem.lesson_group_id, false)
    }
  },
  methods: {
    getCourses() {
      useJwt.getCourses(null, {
        params: {
          type: 'curriculum',
          lesson_type: this.lessonType,
          library_id: this.libraryId,
        },
      }).then(res => {
        this.courses = res.data.data.map(e => ({
          value: e.id, id: e.id, groups: e.groups, text: e.name,
        }))
        this.courses.unshift({ value: null, text: i18n.tc('requests.select-course') })
        this.newItem.course_id = null
        this.newItem.lesson_group_id = null
        this.newItem.lesson_id = null
      })
    },
    getLessonGroups(id, clear) {
      if (clear) this.newItem.lesson_group_id = null
      this.lessonGroups = this.courses[this.courses.findIndex(e => e.id === id)].groups.map(e => ({
        value: e.id, id: e.id, lessons: e.lessons, text: e.name,
      }))
      this.lessonGroups.unshift({ value: null, text: 'Please select a Lesson Group' })
    },
    getLessons(id, clear) {
      if (clear) this.newItem.lesson_id = null
      this.lessons = this.lessonGroups[this.lessonGroups.findIndex(e => e.id === id)].lessons.map(e => ({
        value: e.id, id: e.id, text: e.name,
      }))
      this.lessons.unshift({ value: null, text: 'Please select a Lesson' })
    },
    handleSubmit() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          if (this.mode === 'create') {
            this.createCurriculum()
            return
          }
          this.updateCurriculum()
        }
      })
    },
    createCurriculum() {
      useJwt.createCurriculumPage(this.newItem)
        .then(response => {
          this.$emit('getCurriculum')
          this.showSuccessMessage(response)
        })
        .catch(error => {
          const errors = error.response?.data?.errors
          const errorsKeys = Object.keys(errors)
          if (errorsKeys.length) {
            errorsKeys.forEach(err => {
              this.showError(errors[err][0])
            })
          } else {
            this.showErrorMessage(error)
          }
        }).finally(() => {
          this.isProcessing = false
        })
    },
    updateCurriculum() {
      useJwt.updateCurriculumPage(this.newItem.id, this.newItem)
        .then(response => {
          this.$emit('getCurriculum')
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>

<style>

</style>
