<template>
  <div>
    <page-edit
      :page-id="idOfPageBeingEdited"
      :show="idOfPageBeingEdited!=null"
      @update="getCurriculumPages"
      @close="idOfPageBeingEdited=null"
    />
    <b-row>
      <b-col
        md="6"
      >
        <b-form-group>
          <b-form-input
            :placeholder="$t('search')"
            type="text"
            class="d-inline-block"
            :disabled="isProcessing"
            debounce="500"
            @update="searchTable"
          />
        </b-form-group>
      </b-col>
      <div style="position: absolute;right: 0;margin-right: 35px;">
        <b-button variant="outline-primary"
                  class="mr-2"
                  target="blank"
                  @click="showBatchUpload = true"
        >
          {{ $t('courses-module.batch-upload') }}
        </b-button>
        <b-button variant="outline-primary"
                  tag="a"
                  href="/log-viewer/gec-jobs"
                  class="mr-2"
                  target="blank"
        >
          {{ $t('curriculum-pages-module.queue-explorer') }}
        </b-button>
        <b-button
          variant="outline-primary"
          @click="toggleCreateModal('create-curriculumPage', 'createCurriculumPage')"
        >
          {{ $t("actions.create-curriculumPage") }}
        </b-button>
      </div>
    </b-row>

    <b-row>
      <!-- list of users table -->
      <b-col md="12 mt-2">
        <b-row>
          <b-col>
            <p class="text-capitalize">
              <strong> {{ $t('curriculumPage-module.list-of-curriculum') }}</strong>
            </p>
          </b-col>
        </b-row>

        <b-overlay
          id="overlay-background"
          :show="isProcessing"
          opacity="0.4"
          :no-center="true"
          blur="0.8"
          rounded="sm"
        >
          <curriculum-table
            :pagination-data="paginationData"
            :is-processing="isProcessing"
            :is-clone-processing="isCloneProcessing"
            :curriculums="curriculumPages"
            :normal-lesson-id="normalLessonId"
            @openCurriculumPage="openCurriculumPage"
            @editCurriculumPage="(id)=>idOfPageBeingEdited = id"
            @deleteCurriculumPage="(id)=>idOfPageBeingDeleted = id"
            @editCurriculum="(data)=>newItem = data"
            @getCurriculum="getCurriculumPages"
            @setPerPage="setPerPage"
            @deleteCurriculum="(id)=>idOfCurriculumBeingDeleted=id"
            @cloneCurriculum="cloneCurriculum"
            @onTranslatePage="onTranslatePage"
            @updateCbAudio="handleUpdatePageAssets"
          />
          <template #overlay>
            <div />
          </template>
        </b-overlay>
        <!-- list of users table -->
      </b-col>
    </b-row>
    <create-update-curriculum-modal
      :show="newItem!=null"
      :new-item="newItem"
      :type="type"
      :library-id="selectedLibrary"
      @getCurriculum="()=>{newItem=null;getCurriculumPages()}"
      @close="newItem=null"
    />
    <delete-modal
      name="modal-delete-page"
      :on-delete="deletePage"
      title="Delete"
      :sub-title="$t('curriculum-pages-module.delete-curriculum-page')"
      :is-processing-delete="isProcessing"
      :prop-is-processing="isProcessing"
      :show="!!idOfPageBeingDeleted"
      @close="idOfPageBeingDeleted = null"
    />
    <delete-modal
      name="modal-delete-curriculum"
      :on-delete="deleteCurriculum"
      :title="$t('curriculum-pages-module.delete-curriculum-title')"
      :sub-title="$t('curriculum-pages-module.delete-curriculum-question')"
      :prop-is-processing="isProcessing"
      :show="idOfCurriculumBeingDeleted != null"
      @close="idOfCurriculumBeingDeleted = null"
    />
    <LanguageTranslate v-if="!!pageBeingTranslate"
                       :page="pageBeingTranslate"
                       :library="cbLibraries"
                       @reFetch="$emit('getCurriculum')"
                       @close="pageBeingTranslate = null"
    />
    <ExcelBatchUpload :show="showBatchUpload"
                      @close="showBatchUpload = false"
                      @reFetch="getCurriculumPages()"
    />
    <BulkAssetManager v-if="pageInfoManageAsset"
                      :page-info="pageInfoManageAsset"
                      @close="pageInfoManageAsset = null"
    />
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormInput, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import PageEdit from './components/CurriculumPageEdit.vue'
import CreateUpdateCurriculumModal from './components/CurriculumCreateUpdateModal.vue'
import CurriculumTable from './components/CurriculumTable.vue'
import LanguageTranslate from './components/Tools/LangTranslateView.vue'
import ExcelBatchUpload from './components/ExcelBatchUpload.vue'
import BulkAssetManager from './components/BulkAssetManager.vue'

export default {
  components: {
    BButton,
    CreateUpdateCurriculumModal,
    CurriculumTable,
    BRow,
    BFormInput,
    BCol,
    BFormGroup,
    BOverlay,
    PageEdit,
    DeleteModal,
    LanguageTranslate,
    ExcelBatchUpload,
    BulkAssetManager,
  },
  directives: {
    Ripple,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    cbLibraries: {
      type: Array,
      default: () => [],
    },
    selectedLibrary: {
      type: Number,
      default: 0,
    },
    selectedVersion: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      idOfCurriculumBeingDeleted: null,
      isProcessing: false,
      isCloneProcessing: false,
      idOfPageBeingEdited: null,
      idOfPageBeingViewed: null,
      searchTerm: '',
      idOfPageBeingDeleted: null,
      curriculumPages: [],
      paginationData: {
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        lastPage: 0,
      },
      self: getUserData(),
      newItem: null,
      normalLessonId: null,
      pageBeingTranslate: null,
      showBatchUpload: false,
      isProcessingAudioForSpecificPage: false,
      pageInfoManageAsset: null,
    }
  },

  computed: {
    colorSchemeId() {
      return this.color_schemes.find(c => c.value === this.color_scheme).id
    },
  },

  mounted() {
    this.getCurriculumPages()
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    handleBeforeUnload(event) {
      if (this.isProcessingAudioForSpecificPage) {
        // eslint-disable-next-line no-alert
        if (!confirm('Audio is generating leaving the page will terminate the process. Are you sure you want to continue')) {
          event.preventDefault()
        }
      }
    },
    async handleUpdatePageAssets(row) {
      this.pageInfoManageAsset = row
      // this.isProcessingAudioForSpecificPage = true
      // // eslint-disable-next-line no-param-reassign
      // row.isProcessingAudio = true
      // // eslint-disable-next-line no-restricted-syntax
      // for (const page of row.pages) {
      //   try {
      //     // eslint-disable-next-line no-await-in-loop
      //     await useJwt.automateProblemCbAudio(page.id)
      //     this.showSuccess(`${page.title} audio crafted`)
      //   } catch (error) {
      //     this.showError(`Error crafting audio for page ${page.title}`)
      //     console.error(error)
      //   }
      // }
      // // eslint-disable-next-line no-param-reassign
      // row.isProcessingAudio = false
      // this.isProcessingAudioForSpecificPage = false
    },
    onTranslatePage(value) {
      this.pageBeingTranslate = value
    },
    deletePage() {
      this.isProcessing = true
      useJwt.destroyCurriculumPage(this.idOfPageBeingDeleted).then(response => {
        this.getCurriculumPages()
        this.showSuccessMessage(response)
        this.idOfPageBeingDeleted = false
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    cloneCurriculum(id, sameVersion = null) {
      this.isProcessing = true
      this.isCloneProcessing = true
      useJwt.cloneCurriculumPages(id, {
        params: {
          same_version: sameVersion,
        },
      }).then(res => {
        this.showSuccessMessage(res)
        this.getCurriculumPages()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
        // this.isCloneProcessing = false
      })
    },
    toggleCreateModal() {
      this.newItem = {
        course_id: null, lesson_id: null, lesson_group_id: null, name: '', color_scheme: 'color_scheme_1',
      }
    },
    openCurriculumPage(curriculumId, pageId) {
      const routeData = this.$router.resolve({ name: 'curriculum-pages', params: { curriculumId, pageId } })
      window.open(routeData.href, '_blank')
    },
    searchTable(term) {
      this.searchTerm = term
      this.getCurriculumPages(1)
    },
    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.last_page,
      }
    },
    setPerPage(d) {
      this.paginationData.perPage = d
      this.getCurriculumPages()
    },
    getCurriculumPages(page = this.paginationData.currentPage) {
      const params = {
        page,
        perPage: this.paginationData.perPage,
        search: this.searchTerm,
        library_id: this.selectedLibrary || this.$route.query.library || this.$route.params.library,
        lesson_type: this.type,
        version: this.selectedVersion,
      }
      this.isProcessing = true
      useJwt.getCurriculumPages({
        params,
      }).then(response => {
        this.isProcessing = false
        this.curriculumPages = response.data.data.data
        this.normalLessonId = response.data.data.normal_lesson_type_id
        const { monitor } = response.data
        this.curriculumPages.forEach(e => {
          const { lesson } = e
          e.course_id = lesson ? lesson.lesson_group?.course_id : null
          e.lesson_group_id = lesson ? lesson.lesson_group?.id : null
          // eslint-disable-next-line eqeqeq
          e.queue_jobs = monitor.find(item => item.lesson_page_id == e.id)
          // eslint-disable-next-line eqeqeq
          e.queue_jobs_lang_trans = monitor.find(item => item.lesson_page_id_to_translate == e.id)
          e.isProcessingAudio = false
        })
        this.assignPaginationData(response.data.data)
      })
    },
    deleteCurriculum() {
      this.isProcessing = true
      useJwt.deleteCurriculumPage(this.idOfCurriculumBeingDeleted)
        .then(response => {
          this.getCurriculumPages()
          this.idOfCurriculumBeingDeleted = null
          this.showSuccessMessage(response)
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
