<template>
  <span>
    <b-progress
      v-if="isQueueRunning"
      :max="100"
      height="30px"
      class="progress-bar-info"
    >
      <b-progress-bar
        :value="synchInfo?.progress || 0 "
        variant="success"
      >
        <span class="ml-1">
          <b-spinner
            small
          />
          <span style="color: #423636 !important;position: absolute;margin-top: 7px;margin-left: 5px;">{{ $t('image-library-module.synchronizing') }}
            {{ number_format(synchInfo?.progress || 0, 2) }} %
          </span>
        </span>
      </b-progress-bar>
    </b-progress>
    <b-button v-else-if="hadErrorInQueue"
              v-b-tooltip.hover.top="synchInfo?.exception || synchInfo?.exception_class"
              variant="outline-danger"
              class="ml-2"
              :class="props.btnClass"
              :disabled="props.isSynching"
              @click="$emit('onSync')"
    >
      <b-spinner v-if="props.isSynching"
                 small
      />
      <feather-icon v-else
                    icon="AlertTriangleIcon"
      /> {{ $t('error-retry-sync') }}
    </b-button>

    <b-button v-else
              variant="outline-primary"
              class="ml-2"
              :class="props.btnClass"
              :disabled="props.isSynching"
              @click="$emit('onSync')"
    >
      <div class="progress-bar-line" />
      <b-spinner v-if="props.isSynching"
                 small
      />
      <feather-icon v-else
                    :icon="props.icon"
      /> {{ props.title }}
      <!-- {{ $t('image-library-module.synch-for-wordnet') }} -->
    </b-button>
  </span>
</template>
<script setup>
import {
  BSpinner, BButton, BProgress, BProgressBar,
} from 'bootstrap-vue'
import {
  computed, ref, onMounted, onBeforeUnmount, watch,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'

const props = defineProps({
  isSynching: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: i18n.tc('image-library-module.synch-for-wordnet'),
  },
  icon: {
    type: String,
    default: 'RefreshCcwIcon',
  },
  queueKey: {
    type: String,
    default: 'image-lib-sync',
  },
  btnClass: {
    type: String,
    default: '',
  },
})

const synchInfo = ref(null)
const queueMonitor = ref(null)
const queueMonitorRecent = ref(null)
const isQueueRunning = computed(() => synchInfo.value && !synchInfo.value.finished_at && !synchInfo.value.exception)
const hadErrorInQueue = computed(() => synchInfo.value && synchInfo.value.exception)

const getQueueProgress = () => {
  useJwt.getQueueProgress(props.queueKey).then(response => {
    synchInfo.value = response.data.data.queue_info
    queueMonitor.value = setTimeout(() => {
      if (isQueueRunning.value) {
        getQueueProgress()
      } else {
        clearTimeout(queueMonitor.value)
      }
    }, 2500)
  })
}

const getQueueProgressForRecent = () => {
  useJwt.getQueueProgress(props.queueKey).then(response => {
    synchInfo.value = response.data.data.queue_info
    queueMonitorRecent.value = setTimeout(() => {
      getQueueProgressForRecent()
    }, 2500)
  })
}

onBeforeUnmount(() => {
  clearTimeout(queueMonitor.value)
  clearTimeout(queueMonitorRecent.value)
})
onMounted(() => {
  getQueueProgress()
})

watch(() => props.isSynching, value => {
  if (value) {
    setTimeout(() => {
      getQueueProgressForRecent()
    }, 2500)
  }
})

</script>
<style>
  .progress-bar-line {
    position: absolute;
    left: 0;
    width: 20%;
    background: red;
  }
</style>
