<template>
  <div>
    <validation-observer ref="createForm">
      <b-form @submit.prevent>

        <div>
          <h4>
            <a :href="`/super/problem?lesson_id=${curriculum.lesson?.id}`"
               target="_blank"
            >{{ curriculum.lesson?.name }}</a> <small>Curriculum Builder</small></h4>
        </div>
        <b-row>
          <b-col md="4"
                 class="mb-2"
          >
            <b-form-input v-model="curriculum.page_title"
                          placeholder="Page Name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <images-section
              :images="curriculum.images"
              :curriculum="curriculum"
              @removeImage="removeImage"
              @onImageSelected="(bool, typeNm) => selectImageForRegeneration(bool, typeNm)"
            />
          </b-col></b-row>
        <b-tabs pills>
          <b-row>
            <b-col cols="12">
              <b-tab :title="$t('curriculum-pages-module.statements')">
                <b-col cols="12">
                  <b-form-group
                    :label="$t('curriculum-pages-module.thesis-statement')"
                    label-for="v-thesis-statement"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('curriculum-pages-module.thesis-statement')"
                      rules="required"
                      vid="thesisStatement"
                    >
                      <b-form-input
                        id="v-thesis-statement"
                        v-model="curriculum.thesis_statement"
                        name="v-thesis-statement"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('curriculum-pages-module.thesis-statement')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('curriculum-pages-module.general-statement')"
                    label-for="v-general-statement"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('curriculum-pages-module.general-statement')"
                      rules="required"
                      vid="generalStatement"
                    >
                      <b-form-input
                        id="v-general-statement"
                        v-model="curriculum.general_statement"
                        name="v-general-statement"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('curriculum-pages-module.general-statement')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-tab>
            </b-col>

            <b-col md="12">
              <b-tab :title="$t('curriculum-pages-module.main-points')">
                <b-col
                  v-for="(mainPoint, mainPointIndex) in curriculum.main_points"
                  :key="mainPointIndex"
                  cols="12"
                  :row="mainPointIndex"
                >
                  <b-form-group
                    :label="$t('curriculum-pages-module.main-point')"
                    label-for="v-main-point"
                  >
                    <b-row>
                      <b-col cols="11">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('curriculum-pages-module.main-point')"
                          rules="required"
                          vid="main_points_[mainPointIndex]"
                        >
                          <b-form-input
                            id="v-main-point"
                            v-model="mainPoint.content"
                            name="mainPoint.content"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('curriculum-pages-module.main-point')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="1">
                        <b-button
                          v-show="mainPointIndex > 0"
                          v-if="mainPoint.order_index > 3"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon"
                          variant="danger"
                          @click="removeMainPoint(mainPointIndex)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-row>
                  <b-col
                    md="12 text-left"
                    class="d-flex justify-content-end"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    @click="addMainPoint(mainPointIndex)"
                  ><feather-icon icon="PlusCircleIcon" /> {{ $t('curriculum-pages-module.add-fields') }}
                  </b-button>
                  </b-col>
                </b-row>
              </b-tab>
            </b-col>
          </b-row>
        </b-tabs>

        <b-tabs
          pills
          class="mt-2"
        >
          <b-row>
            <!-- questions -->
            <!-- <b-col cols="12">
              <b-tab
                :title="$t('curriculum-pages-module.questions')"
              >
                <b-col
                  lg="12"
                  md="12"
                  class="mb-1"
                >
                  <validation-observer ref="contents section">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('curriculum-pages-module.questions')"
                      rules="required"
                      vid="questions"
                    >
                      <h5>{{ $t("lesson-pages-module.questions") }}</h5>
                      <editor v-model="curriculum.review_questions" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small>
                        Note: @@@Youtube video Link@@@
                      </small>
                    </validation-provider>
                  </validation-observer>
                </b-col>
              </b-tab>
            </b-col> -->
            <!-- questions -->

            <!-- extra questions -->
            <b-col cols="12">
              <b-tab :title="$t('curriculum-pages-module.extra-questions-tab')">
                <b-col
                  lg="12"
                  md="12"
                  class="mb-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('curriculum-pages-module.extra-questions-tab')"
                    rules="required"
                    vid="extra-questions"
                  >
                    <h5>{{ $t("lesson-pages-module.extra-questions") }}</h5>
                    <editor v-model="curriculum.extra_question" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-tab>
            </b-col>
            <!-- extra questions -->

            <!-- bottom questions -->
            <b-col cols="12">
              <b-tab :title="$t('curriculum-pages-module.bottom-questions-tab')">
                <b-col
                  lg="12"
                  md="12"
                  class="mb-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('curriculum-pages-module.bottom-questions-tab')"
                    rules="required"
                    vid="bottom-questions"
                  >
                    <h5>{{ $t("lesson-pages-module.bottom-questions") }}</h5>
                    <editor v-model="curriculum.bottom_question" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-tab>
            </b-col>
            <!-- bottom questions -->
          </b-row>
        </b-tabs>

        <!--- second input -->

        <b-tabs
          ref="setTab"
          v-model="activeSet"
          pills
        >
          <b-row>
            <b-col :set="(problemType = getProblemType(activeSet + 1))">
              <b-form-group
                v-if="problemType.length"
                :label="$t('curriculum-pages-module.generated-problem')"
              >
                <b-button-group>
                  <b-button
                    v-for="(pt,index) of problemType"
                    :key="index"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    tag="a"
                    target="_blank"
                    :href="`/${AUTH_USER().usertype}/problem/edit?lesson_id=${ pt.lesson_id}&problem_id=${pt.id}&lang_level=${curriculum.lang_level}`"
                    variant="outline-primary"
                  >
                    {{ capitalizeFirstLetter(pt.type) }}
                  </b-button>
                </b-button-group>
              </b-form-group>
            </b-col>
            <b-col
              class="text-right"
            >
              <span>
                <b-button
                  v-show="activeSet>0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon mr-1"
                  variant="danger"
                  @click="removeSets(activeSet)"
                ><feather-icon icon="DeleteIcon" />
                  {{ $t('curriculum-pages-module.remove-set') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="addParagraph()"
                ><feather-icon icon="PlusCircleIcon" />
                  {{ $t('curriculum-pages-module.add-paragraph') }}
                </b-button>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">

              <b-tab
                v-for="(setInput, k) in curriculum.sets"
                :key="`setTab-${k}`"
                :row="k"
                :title="$t('curriculum-pages-module.paragraph', {number: k+1})"
              >
                <h4>
                  {{ $t('curriculum-pages-module.set') }} {{ k + 1 }}
                </h4>
                <b-row class="mt-2 mb-1">
                  <b-col
                    v-for="problemSet of curriculumSetProblems"
                    :key="problemSet.title"
                    class="text-center"
                  >
                    <h4>{{ problemSet.title }}</h4>
                    <div
                      class="d-flex justify-content-center"
                      :set="component = problemSet.types.length > 1 ? 'b-form-radio' : 'b-form-checkbox'"
                    >
                      <b-form-checkbox
                        v-for="problem,index in problemSet.types"
                        :id="`problem-${k}-${problemSet.title}-${index}`"
                        :key="index"
                        v-model.lazy="setInput.selectedStandard"
                        :name="`problem-${k}-${problemSet.title}`"
                        :value="problem"
                        :disabled="!canEditProblemType"
                        @change="updateSelectedProblems(k,problem, setInput, problemSet.types, problemSet.multiple)"
                      >
                        <label
                          class="mr-1"
                          :for="`problem-${k}-${problemSet.title}-${index}`"
                        >{{ problem }}</label>
                      </b-form-checkbox>
                    </div>
                    <!-- <div
                          class="text-center mb-1  font-weight-bold text-nowrap"
                          :class="{
                            'd-flex':problemSet.key=='skill_type'
                          }"
                        >
                          {{ problemSet.title }}
                          <b-form-select
                            v-if="curriculum.sets[activeSet] && problemSet.key=='skill_type'"
                            v-model="curriculum.sets[activeSet].skill_type"
                            :options="skillTypes"
                            class="ml-2"
                            style="width: 150px;"
                            :disabled="!curriculum.can_edit_question_type"
                            size="sm"
                          />
                        </div>
                        <div
                          class="text-right justify-content-center d-flex"
                          :class="{
                            'mr-1':problemSet.key=='skill_type'
                          }"
                        >
                          <b-form-checkbox
                            v-for="problem,index in problemSet.types"
                            :id="`problem-${k}-${problem}`"
                            :key="index"
                            v-model="setInput.selectedStandard"
                            :name="`problem-${k}-${problem}`"
                            :value="problem"
                            @change="updateSelectedProblems(k,problem)"
                          >
                            <label
                              class="mr-1"
                              :for="`problem-${k}-${problem}`"
                            >{{ problem }}</label>
                          </b-form-checkbox>
                        </div> -->
                  </b-col>
                </b-row>
                <div
                  style="border: 1px solid black; background-color: #d0e1f8"
                  class="p-2 m-1"
                >
                  <b-row>
                    <b-col cols="8">
                      <b-form-group
                        :label="$t('curriculum-pages-module.question')"
                        label-for="v-question"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('curriculum-pages-module.question')"
                          rules="required"
                          vid="question[k]"
                        >
                          <b-form-input
                            id="v-question"
                            v-model="setInput.question.content"
                            name="v-question"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('curriculum-pages-module.question')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <image-upload
                        :title="$t('curriculum-pages-module.image')"
                        :generator-text="setInput.question.content"
                        :is-required="false"
                        style="height: 100px"
                        :url="setInput.question.image"
                        @onSelectedFromLibrary="value => setInput.question.image = value"
                        @imageRemoved="
                          () => {
                            removeImageMeta(setInput.question)
                          }
                        "
                        @imageUploaded="
                          (file, url) => {
                            setInput.question.image = url;
                          }
                        "
                        @onImageSelected="bool => selectImageForRegeneration(bool, 'question_image', k)"
                      />
                    </b-col>
                    <b-col md="2 pt-2">
                      <Recorder
                        :url="setInput.question.audio"
                        :polly-text="setInput.question.content"
                        @onRemove="setInput.question.audio = ''"
                        @input="setQuestionAudio(k, 'question', $event)"
                        @onSelectedFromLibrary="value => setAudioFromLibrary(k, 'question', value)"
                        @fileTypeInvalid="
                          setError([`quesion-audio-${level}`], $event)
                        "
                        @fileSizeInvalid="
                          setError([`quesion-audio-${level}`], $event)
                        "
                      />
                    </b-col>
                  </b-row>
                  <b-row class="pt-1">
                    <b-col cols="4">
                      <b-form-group
                        :label="$t('curriculum-pages-module.vocabulary-word')"
                        label-for="v-voca-word"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('curriculum-pages-module.vocabulary-word')"
                          rules="required"
                          vid="question_vocaWord[k]"
                        >
                          <WordAutoComplete
                            :term="setInput.question.vocabulary.word_in_eng"
                            @onWordSelected="(value) => {
                              setInput.question.vocabulary.word_in_eng = value.vocab_word
                              setInput.question.vocabulary.word_in_eng_id = value.id
                              setInput.question.vocabulary.synonym_1 = value.synonyms[0]?.text;
                              setInput.question.vocabulary.synonym_2 = value.synonyms[1]?.text
                            }"
                          >
                            <b-form-input
                              id="v-voca-word"
                              v-model="setInput.question.vocabulary.word_in_eng"
                              name="v-voca-word"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('curriculum-pages-module.vocabulary-word')"
                            />
                          </WordAutoComplete>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                        :label="$t('curriculum-pages-module.vocabulary-desc')"
                        label-for="v-voca-word"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('curriculum-pages-module.vocabulary-desc')"
                          rules="required"
                          vid="question_vocaWord[k]"
                        >
                          <b-form-input
                            id="v-voca-word"
                            v-model="setInput.question.vocabulary.description"
                            name="v-voca-word"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('curriculum-pages-module.vocabulary-desc')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <label>{{ $t('curriculum-pages-module.vocabulary-image') }}</label>
                      <image-upload
                        :title="$t('curriculum-pages-module.image')"
                        :is-required="false"
                        style="height: 100px"
                        :url="setInput.question.vocabulary.image"
                        :generator-text="setInput.question.vocabulary.word_in_eng"
                        @onSelectedFromLibrary="value => setInput.question.vocabulary.image = value"
                        @imageRemoved="
                          () => {
                            removeImageMeta( setInput.question.vocabulary)
                          }
                        "
                        @imageUploaded="
                          (file, url) => {
                            setInput.question.vocabulary.image = url;
                          }
                        "
                        @onImageSelected="bool => selectImageForRegeneration(bool, 'QUESTION_VOCAB', k)"
                      />
                    </b-col>
                    <b-col md="2">
                      <label>{{ $t('curriculum-pages-module.vocabulary-audio') }}</label>
                      <Recorder
                        :url="setInput.question.vocabulary.audio"
                        :polly-text="setInput.question.vocabulary.word_in_eng"
                        @onRemove="setInput.question.vocabulary.audio = ''"
                        @input="setQuestionAudio(k,'vocabulary',$event)"
                        @onSelectedFromLibrary="value => setAudioFromLibrary(k, 'vocabulary', value)"
                        @fileTypeInvalid="
                          setError([`answer-audio-${level}`], $event)
                        "
                        @fileSizeInvalid="
                          setError([`answer-audio-${level}`], $event)
                        "
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        :label="$t('curriculum-pages-module.synonym', {number:'1'})"
                        label-for="v-synonym1"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('curriculum-pages-module.synonym', {number:'1'})"
                          rules="required"
                          vid="question_synonym1[k]"
                        >
                          <b-form-input
                            v-if="setInput.question.vocabulary"
                            id="v-synonym1"
                            v-model="setInput.question.vocabulary.synonym_1"
                            name="v-synonym1"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('curriculum-pages-module.synonym', {number:'1'})"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                        :label="$t('curriculum-pages-module.synonym', {number:'2'})"
                        label-for="v-synonym2"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('curriculum-pages-module.synonym', {number:'2'})"
                          rules="required"
                          vid="question_synonym2[k]"
                        >
                          <b-form-input
                            v-if="setInput.question.vocabulary"
                            id="v-synonym2"
                            v-model="setInput.question.vocabulary.synonym_2"
                            name="v-synonym2"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('curriculum-pages-module.synonym', {number:'2'})"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="3"
                      class="align-items-center pt-2"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          v-if="setInput.question.vocabulary"
                          :id="`is-public-new-${k}`"
                          v-model="setInput.question.vocabulary.is_public"
                          switch
                          name="is_public"
                        >
                          <label :for="`is-public-new-${k}`">{{ $t('curriculum-pages-module.is-public') }}</label>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div
                  class="p-2 m-1"
                  style="border: 1px solid black; background-color: #c1ecc3"
                >
                  <div
                    v-for="(answer, index) in setInput.answers"
                    :key="index"
                    class="pt-1"
                  >
                    <hr
                      v-if="index != 0"
                      class="pb-1"
                      style="border-color: black"
                    >
                    <b-row>
                      <b-col cols="8">
                        <b-form-group
                          :label="$t('curriculum-pages-module.full-sent-ans', {number:index + 1})"
                          :label-for="`full-sent-ans${index + 1}`"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('curriculum-pages-module.full-sent-ans', {number:index + 1})"
                            rules="required"
                            vid="full_sent_ans[k]"
                          >
                            <b-form-input
                              v-model="answer.content"
                              name="full-sent-ans1"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('curriculum-pages-module.full-sent-ans', {number:index + 1})"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <multiple-image-picker
                          v-if="index === 0"
                          :selected-images="answer.image"
                          @updateData="(data) => answer.image = data"
                        />
                        <image-upload
                          v-else
                          :title="$t('curriculum-pages-module.image')"
                          :is-required="false"
                          style="height: 100px"
                          :url="answer.image"
                          @onSelectedFromLibrary="value => answer.image = value"
                          @imageRemoved="
                            () => {
                              removeImageMeta(answer)
                            }
                          "
                          @imageUploaded="
                            (file, url) => {
                              answer.image = url;
                            }
                          "
                        />
                        <b-form-input
                          v-if="index !== 0"
                          v-model="answer.image_caption"
                          placeholder="caption"
                        />
                      </b-col>
                      <b-col md="2 pt-2">
                        <Recorder
                          :url="answer.audio"
                          :polly-text="answer.content"
                          @onRemove="answer.audio = ''"
                          @input="setAnswerAudio(k, index, 'answer', $event)"
                          @onSelectedFromLibrary="value => word.audio = value"
                          @fileTypeInvalid="
                            setError([`answer-audio-${level}`], $event)
                          "
                          @fileSizeInvalid="
                            setError([`answer-audio-${level}`], $event)
                          "
                        />
                      </b-col>
                    </b-row>
                    <b-row class="pt-1">
                      <b-col cols="4">
                        <b-form-group
                          :label="$t('curriculum-pages-module.vocabulary-word')"
                          label-for="voca_word"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('curriculum-pages-module.vocabulary-word')"
                            rules="required"
                            vid="ans1_vocaWord[k]"
                          >
                            <WordAutoComplete
                              :term="answer.vocabulary.word_in_eng"
                              @onWordSelected="(value) => {
                                answer.vocabulary.word_in_eng = value.vocab_word
                                answer.vocabulary.word_in_eng_id = value.id
                                answer.vocabulary.synonym_1 = value.synonyms[0]?.text;
                                answer.vocabulary.synonym_2 = value.synonyms[1]?.text
                              }"
                            >
                              <b-form-input
                                v-if="answer.vocabulary"
                                id="ans1-voca-word"
                                v-model="answer.vocabulary.word_in_eng"
                                name="ans1-voca-word"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('curriculum-pages-module.vocabulary-word')"
                              />
                            </WordAutoComplete>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group
                          :label="$t('curriculum-pages-module.vocabulary-desc')"
                          label-for="voca_word"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('curriculum-pages-module.vocabulary-desc')"
                            rules="required"
                            vid="ans1_vocaWord[k]"
                          >
                            <b-form-input
                              v-if="answer.vocabulary"
                              id="ans1-voca-word"
                              v-model="answer.vocabulary.description"
                              name="ans1-voca-word"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('curriculum-pages-module.vocabulary-desc')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="2">
                        <label>{{ $t('curriculum-pages-module.vocabulary-image') }}</label>
                        <image-upload
                          :title="$t('curriculum-pages-module.image')"
                          :is-required="false"
                          style="height: 100px"
                          :url="answer.vocabulary?.image"
                          :generator-text="answer.vocabulary.word_in_eng"
                          @onSelectedFromLibrary="value => answer.vocabulary.image = value"
                          @imageRemoved="
                            () => {
                              removeImageMeta(answer.vocabulary)
                            }
                          "
                          @imageUploaded="
                            (file, url) => {
                              answer.vocabulary.image = url;

                            }
                          "
                          @onImageSelected="bool => selectImageForRegeneration(bool, 'ANSWER_VOCAB', k)"
                        />
                      </b-col>
                      <b-col md="2">
                        <label>{{ $t('curriculum-pages-module.vocabulary-audio') }}</label>
                        <Recorder
                          :url="answer.vocabulary.audio"
                          :polly-text="answer.vocabulary.word_in_eng"
                          @onRemove="answer.vocabulary.audio = ''"
                          @input="setAnswerAudio(k, index, 'vocabulary', $event)"
                          @onSelectedFromLibrary="value => setAudioAudioFromLibrary(k, index,'vocabulary', value)"
                          @fileTypeInvalid="
                            setError([`answer-audio-${level}`], $event)
                          "
                          @fileSizeInvalid="
                            setError([`answer-audio-${level}`], $event)
                          "
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          :label="$t('curriculum-pages-module.synonym', {number:'1'})"
                          label-for="v-synonym1"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('curriculum-pages-module.synonym', {number:'1'})"
                            rules="required"
                            vid="ans1_synonym1[k]"
                          >
                            <b-form-input
                              v-if="answer.vocabulary"
                              id="ans1-synonym1"
                              v-model="answer.vocabulary.synonym_1"
                              name="ans1-synonym1"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('curriculum-pages-module.synonym', {number:index + 1})"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group
                          :label="$t('curriculum-pages-module.synonym', {number:2})"
                          label-for="ans1-synonym2"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('curriculum-pages-module.synonym', {number:2})"
                            rules="required"
                            vid="ans1_synonym2[k]"
                          >
                            <b-form-input
                              v-if="answer.vocabulary"
                              id="ans1-synonym2"
                              v-model="answer.vocabulary.synonym_2"
                              name="ans1-synonym2"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('curriculum-pages-module.synonym', {number:2})"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="3"
                        class="align-items-center pt-2"
                      >
                        <b-form-group>
                          <b-form-checkbox
                            v-if="answer.vocabulary"
                            :id="`ans-is-public-${index}-${k}`"
                            v-model="answer.vocabulary.is_public"
                            switch
                            name="is_public"
                          >
                            <label
                              :for="`ans-is-public-${index}-${k}`"
                            >{{ $t('curriculum-pages-module.is-public') }}</label>
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col
                            cols="3"
                            class="align-items-center pt-2"
                          >
                            <b-form-group>
                              <b-form-checkbox
                                v-if="answer.vocabulary"
                                :id="`ans-is-public-${index}-${k}`"
                                v-model="answer.vocabulary.is_public"
                                name="is_public"
                              >
                                <label
                                  :for="`ans-is-public-${index}-${k}`"
                                >Is Public</label>
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col> -->
                    </b-row>
                    <b-col
                      cols="1"
                      class="mt-2"
                    >
                      <b-button
                        v-show="index != 0"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="btn-icon mr-1"
                        variant="danger"
                        @click="removeAnswer(k, index)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-col>
                    <b-col class="d-flex justify-content-end">
                      <b-button
                        v-show="index == setInput.answers.length - 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        @click="addAnswer(k)"
                      >
                        <feather-icon icon="PlusCircleIcon" />
                        {{ $t('curriculum-pages-module.add-answer') }}
                      </b-button>
                    </b-col>
                  </div>
                </div>
                <div
                  class="p-2 m-1"
                  style="border: 1px solid black; background-color: rgb(236 196 196)"
                >
                  <b-row>
                    <b-col
                      v-for="(wrongAns, index) in setInput.wrongAnswers"
                      :key="index"
                      md="4"
                    >
                      <b-form-group :label="$t('curriculum-pages-module.wrong-answer')">
                        <b-form-input
                          v-model="wrongAns.content"
                          :placeholder="$t('curriculum-pages-module.wrong-answer')"
                        />
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon mr-1 mt-1"
                          size="sm"
                          variant="danger"
                          @click="setInput.wrongAnswers.splice(index, 1)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="d-flex justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      @click="addWrongAnswer(k)"
                    >
                      <feather-icon icon="PlusCircleIcon" />
                      {{ $t('curriculum-pages-module.add-answer') }}
                    </b-button>
                  </div>
                </div>
                <b-row>
                  <b-col
                    class="px-1 mx-3"
                    md="4"
                  >
                    <b-form-group :label="$t('curriculum-pages-module.domain')">
                      <b-form-select
                        v-model="setInput.domain"
                        size="sm"
                        :options="curriculum.domains"
                        value-field="id"
                        text-field="name"
                      />
                    </b-form-group>
                    <b-form-group :label="$t('curriculum-pages-module.standard')">
                      <b-form-select
                        v-model="setInput.standard"
                        size="sm"
                        :options="curriculum.domains.find(item => item.id == setInput.domain)
                          ? curriculum.domains.find(item => item.id == setInput.domain).standards
                          : []"
                        value-field="id"
                        text-field="name"
                      />
                    </b-form-group>
                    <b-form-group :label="$t('curriculum-pages-module.engine')">
                      <b-form-select
                        v-model="setInput.ddf_set"
                        size="sm"
                        :options="ddfOption"
                        value-field="value"
                        text-field="text"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- statement/feedback audio -->
                  <b-col
                    class=""
                    md="4"
                  >
                    <b-card
                      no-body
                      class="border p-2"
                    >
                      <h5>{{ $t('curriculum-pages-module.statement-audio') }}</h5>
                      <Recorder
                        :url="setInput.statementAudio"
                        :polly-text="getStatementAudioForPolly(setInput)"
                        @onRemove="setInput.statementAudio = ''"
                        @input="setStatementFeedBackAudio(setInput, 'statementAudio' , $event)"
                        @onSelectedFromLibrary="value => setInput['statementAudio'] = value"
                        @fileTypeInvalid="
                          setError([`Statement-audio`], $event)
                        "
                        @fileSizeInvalid="
                          setError([`Statement-audio`], $event)
                        "
                      />
                    </b-card>
                  </b-col>
                  <b-col
                    md="3"
                  >
                    <b-card
                      no-body
                      class="border p-2"
                    >
                      <h5>
                        {{ $t('curriculum-pages-module.feedback-audio') }}
                      </h5>
                      <Recorder
                        :url="setInput.feedbackAudio"
                        :polly-text="setInput?.question?.content + ' .' + setInput?.answers[0]?.content"
                        @onRemove="setInput.feedbackAudio = ''"
                        @input="setStatementFeedBackAudio(setInput, 'feedbackAudio', $event)"
                        @onSelectedFromLibrary="value => setInput['feedbackAudio'] = value"
                        @fileTypeInvalid="
                          setError([`Statement-audio`], $event)
                        "
                        @fileSizeInvalid="
                          setError([`Statement-audio`], $event)
                        "
                      />
                    </b-card>
                  </b-col>
                  <b-col class="px-1 mx-3">
                    <problem-rules :set-input="setInput" />
                  </b-col>
                </b-row>
                <b-overlay :show="isProcessingWord">
                  <b-row class="mx-0">
                    <b-col
                      v-if="setInput.paragraph.images && showParagraphWords"
                      cols="12"
                      class="p-2 m-1"
                    >
                      <div class="row">
                        <div
                          v-for="(word,i) in ARRAY_UNIQUE_BY_KEY(setInput.paragraph.images, 'word')"
                          :key="i"
                          class="col-lg-2 border border-secondary"
                        >
                          <GeneratedWord :word="word"
                                         :remove-words="removeWords"
                                         :k="k"
                                         :i="i"
                                         :image-selected="imageSelected"
                                         :set-word-selector-audio="setWordSelectorAudio"
                                         :update-incorrect-words-for-paragraph="updateIncorrectWordsForParagraph"
                                         @onImageSelected="v=> selectImageForRegeneration(v, word.word, k, 'generatedWord')"
                          />
                        </div>
                      </div>
                    </b-col>
                    <!-- paragraph -->
                    <b-col
                      cols="12"
                      class="text-right"
                    >
                      <b-button
                        variant="primary"
                        :disabled="wordProcessing"
                        @click="()=> { checkRegex(k); showParagraphWords = true }"
                      >
                        <b-spinner
                          v-if="wordProcessing"
                          small
                        />
                        <feather-icon icon="PlusIcon" />
                        {{ $t('curriculum-pages-module.generate-word') }}
                      </b-button>
                      <b-button
                        v-if="showParagraphWords"
                        variant="outline-primary ml-2"
                        @click="()=>{showParagraphWords=false}"
                      >
                        <feather-icon icon="MinusIcon" />
                        {{ $t('curriculum-pages-module.hide') }}
                      </b-button>
                    </b-col>
                    <b-col
                      cols="12"
                      class="px-2 mx-1"
                    >
                      <label for="paragraph">{{
                        $t("labels.paragraph")
                      }}</label>
                      <editor
                        id="paragraph"
                        v-model="setInput.paragraph.content"
                        rows="3"
                        name="paragraph"
                        placeholder="paragraph"
                      />
                      <small>{{
                        $t("messages.curriculum-builder-paragraph-note")
                      }}</small>
                    </b-col>
                  </b-row>
                </b-overlay>
              </b-tab>
            </b-col>

            <b-col md="12">
              <!-- Wordnet -->
              <word-net
                type="curriculum-builder"
                :page-id="pageId"
                :main-text="mainText"
              />
              <!-- WOrdnet -->
            </b-col>
          </b-row>
        </b-tabs>

      </b-form>
    </validation-observer>
    <b-col
      md="12"
      class="text-right"
      sm="12"
    >
      <b-button
        variant="primary"
        class="btn btn-primary mr-1"
        :disabled="processingMethod"
        @click="submitData(false)"
      >
        <b-spinner
          v-show="processingMethod === 'update'"
          class="mr-1"
          small
          variant="light"
        />
        {{ $t("lesson-pages-module.update") }}
      </b-button>
      <b-button
        variant="primary"
        class="btn btn-success"
        :disabled="processingMethod"
        @click="submitData(true)"
      >
        <b-spinner
          v-show="processingMethod === 'update-generate'"
          class="mr-1"
          small
          variant="light"
        />
        {{ $t("lesson-pages-module.update-and-generate") }}
      </b-button>
      <b-button
        variant="primary"
        class="btn btn-info ml-2"
        :disabled="processingMethod"
        @click="submitData(true, 'vocab')"
      >
        <b-spinner
          v-show="processingMethod === 'update-generate'"
          class="mr-1"
          small
          variant="light"
        />
        {{ $t("lesson-pages-module.generate-vocab-lesson") }}
      </b-button>
      <b-button
        variant="secondary"
        class="btn ml-2"
        :disabled="processingMethod"
        @click="submitData(false, 'automate-audio')"
      >
        <b-spinner
          v-show="!!processingMethod"
          class="mr-1"
          small
          variant="light"
        />
        {{ $t("lesson-pages-module.generate-audio") }}
      </b-button>
      <b-button
        variant="warning"
        class="btn btn-secondary ml-2"
        :disabled="processingMethod"
        @click="submitData(false, 'automate-image')"
      >
        <b-spinner
          v-show="!!processingMethod"
          class="mr-1"
          small
          variant="light"
        />
        {{ $t("lesson-pages-module.generate-image") }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BSpinner,
  BFormCheckbox,
  BTabs,
  BTab,
  BOverlay,
  BFormSelect,
  BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import i18n from '@/libs/i18n'
import Editor from '@views/common/components/QuillEditorCustom.vue'
import ImageUpload from '@views/common/components/CustomImageUpload.vue'
import useJwt from '@/auth/jwt/useJwt'
import WordNet from '@/views/common/lessons/components/WordNetSection.vue'
import Recorder from '@/views/super/problem/components/recorder.vue'
import {
  CURRICULUM_SET_READING_PROBLEM_TYPE, CURRICULUM_SET_LISTENING_PROBLEM_TYPE, CURRICULUM_SET_WRITING_PROBLEM_TYPE, CURRICULUM_SET_SPEECH_PROBLEM_TYPE,
} from '@/const/problemTypes'
import SET_STANDARD from '@/const/setStandards'
import WordAutoComplete from '@/views/common/components/WordAutocomplete.vue'
import ImagesSection from './ImagesSection.vue'
import MultipleImagePicker from './Tools/MultipleImagePicker.vue'
import ProblemRules from './Tools/ProblemRules.vue'
import GeneratedWord from './Tools/GeneratedWord.vue'

export default {
  components: {
    ImagesSection,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    Editor,
    BSpinner,
    WordNet,
    BFormCheckbox,
    // BBadge,
    ImageUpload,
    BTabs,
    BTab,
    Recorder,
    BOverlay,
    BFormSelect,
    BButtonGroup,
    MultipleImagePicker,
    ProblemRules,
    WordAutoComplete,
    GeneratedWord,
    // BFormRadio,
  },
  directives: {
    Ripple,
  },
  props: {
    pageId: {
      type: [Number, String],
      required: true,
    },
    curriculum: {
      type: Object,
      required: true,
    },
    autoGeneratedProblems: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      processingMethod: false,
      originalCurriculum: {},
      selectedProblems: {},
      file: '',
      activeSet: this.$route.query.set_no ? this.$route.query.set_no - 1 : 0,
      showParagraphWords: false,
      wordProcessing: false,
      ddfOption: [
        { value: null, text: i18n.tc('curriculum-pages-module.none') },
        { value: 1, text: i18n.tc('curriculum-pages-module.primary') },
        { value: 2, text: i18n.tc('curriculum-pages-module.secondary') },
        { value: 0, text: i18n.tc('curriculum-pages-module.exclude') },
      ],
      skillTypes: [
        {
          value: null,
          text: i18n.tc('curriculum-pages-module.select-skill-type'),
          disabled: true,
        },
        {
          value: 'reading',
          text: i18n.tc('curriculum-pages-module.reading'),
        },
        {
          value: 'listening',
          text: i18n.tc('curriculum-pages-module.listening'),
        },
      ],
      selectedSkillType: null,
      isProcessingWord: false,
      selectedImageForRegeneration: [],
    }
  },
  computed: {
    mainText() {
      return this.getMainText()
    },
    pageNo() {
      return this.curriculum?.page_no
    },
    canEditProblemType() {
      // eslint-disable-next-line eqeqeq
      return this.curriculum.version == 4 || !!this.curriculum?.is_linear_lesson
    },
    actualActiveSet() {
      return this.activeSet + 1
    },
    curriculumSetProblems() {
      return [
        {
          title: i18n.tc('curriculum-pages-module.reading'),
          key: 'reading',
          selected: '',
          multiple: false,
          types: CURRICULUM_SET_READING_PROBLEM_TYPE,
        },
        {
          title: i18n.tc('curriculum-pages-module.listening'),
          key: 'reading',
          selected: '',
          multiple: false,
          types: CURRICULUM_SET_LISTENING_PROBLEM_TYPE,
        },
        {
          title: i18n.tc('curriculum-pages-module.writing'),
          key: 'reading',
          selected: '',
          multiple: true,
          types: CURRICULUM_SET_WRITING_PROBLEM_TYPE,
        },
        {
          title: i18n.tc('curriculum-pages-module.speaking'),
          key: 'reading',
          selected: '',
          multiple: true,
          types: CURRICULUM_SET_SPEECH_PROBLEM_TYPE,
        },
      ]
    },
  },
  created() {
    this.originalCurriculum = JSON.parse(JSON.stringify(this.curriculum))
  },
  mounted() {
    this.config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    }

    this.curriculum.sets.forEach((set, i) => {
      this.selectedProblems[i] = []
      this.curriculum.sets[i].paragraph.temporaryImages = {}
      if (!set.paragraph.images) {
        this.curriculum.sets[i].paragraph.images = []
      }
      useJwt.getWordsForParagraph({ pageId: this.pageId, setNo: set.set_no }).then(res => {
        this.curriculum.sets[i].paragraph.images = res.data.data.words
        this.mapWords(i, res.data.data.words)
        this.orderWords(i)
      })
    })
    this.$root.$off('applyImageRegeneration')
    this.$root.$on('applyImageRegeneration', this.selectImageForRegeneration)
  },
  methods: {
    selectImageForRegeneration(isSelected, contentType, setNo = null, type = 'pageMeta') {
      const setNum = setNo !== null ? setNo + 1 : null
      if (isSelected) {
        this.selectedImageForRegeneration.push({
          type,
          content: contentType,
          setNo: setNum,
        })
      } else {
        this.selectedImageForRegeneration = this.selectedImageForRegeneration.filter(i => !(i.content === contentType && i.setNo === setNum))
      }
    },
    automateAudio() {
      this.processingMethod = 'automate-audio'
      useJwt.automateProblemCbAudio(this.pageId).then(response => {
        this.showSuccessMessage(response)
        this.generateProblems()
      }).catch(error => {
        this.processingMethod = null
        this.showErrorMessage(error)
      })
    },
    automateImage() {
      const startUpdate = (selectedOnly = false) => {
        this.processingMethod = 'automate-image'
        useJwt.automateProblemCbImage(this.pageId, {
          selected_only: selectedOnly ? 1 : null,
          selected_content: selectedOnly ? this.selectedImageForRegeneration : null,
        }).then(response => {
          this.showSuccessMessage(response)
          this.$emit('updated')
        }).catch(error => {
          this.processingMethod = null
          this.showErrorMessage(error)
        })
      }
      if (this.selectedImageForRegeneration.length) {
        this.$swal.fire({
          title: 'Do you want to save the changes?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, Generate for Selected',
          denyButtonText: 'No, for Generate All',
        }).then(result => {
        /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            startUpdate(true)
          } else if (result.isDenied) {
            startUpdate()
          }
        })
      } else {
        startUpdate()
      }
    },
    getStatementAudioForPolly(paragraph) {
      const regex = /\*\*(.*?)\*\*/g

      // Replace each match with the first string before commas
      const result = paragraph?.paragraph?.content.replace(regex, (match, captured) => {
        // Split the captured text by commas and take the first part
        const parts = captured.split(',')
        return parts[0]
      })

      return `${paragraph?.question?.content} ${result}`.replace(/(<([^>]+)>)/gi, '')
    },
    setError(i, error) {
      this.showErrorMessage(error)
    },
    mapWords(i, words) {
      // eslint-disable-next-line array-callback-return
      words.map(w => {
        this.curriculum.sets[i].paragraph.temporaryImages[w.word] = {
          image: w.image, id: w.id, audio: w.audio, incorrect_words: w.incorrect_words,
        }
      })
    },
    updateSelectedProblems(index, val, mutator, toRemove, multiple = false) {
      const newValue = mutator.selectedStandard
      setTimeout(() => {
        // eslint-disable-next-line valid-typeof
        let currentlySelected = typeof (mutator.selectedStandard) === 'string' ? [mutator.selectedStandard] : mutator.selectedStandard
        if (!multiple) {
          currentlySelected = [...currentlySelected.filter(item => !toRemove.includes(item))]
        }

        if (newValue.includes(val)) {
          currentlySelected.push(val)
        }
        currentlySelected = [...new Set(currentlySelected)]
        // eslint-disable-next-line no-param-reassign
        mutator.selectedStandard = currentlySelected

        const existingIndex = this.selectedProblems[index].indexOf(val)
        if (existingIndex > -1) {
          this.selectedProblems[index].splice(existingIndex, 1)
        } else {
          this.selectedProblems[index].push(val)
        }
      }, 100)
    },
    deletePageMeta(id) {
      return new Promise(resolve => {
        useJwt.deletePageMeta(id).then(res => {
          this.showSuccessMessage(res)
          resolve('done')
        })
      })
    },
    addWrongAnswer(setIndex) {
      const newAnswer = {
        content: 'WrongAnswers',
      }
      this.curriculum.sets[setIndex].wrongAnswers.push(newAnswer)
    },
    addAnswer(setIndex) {
      const newAnswer = {
        content: 'Answer',
        image: '',

        audio: '',
        vocabulary: {
          word_in_eng: '',
          description: '',
          synonym_1: '',
          synonym_2: '',
          image: '',
          audio: '',
        },
      }
      this.curriculum.sets[setIndex].answers.push(newAnswer)
    },

    removeAnswer(setIndex, ansIndex) {
      const newCurri = JSON.parse(JSON.stringify(this.curriculum))
      const deletingAnswer = newCurri.sets[setIndex].answers[ansIndex]

      newCurri.sets[setIndex].answers.splice(ansIndex, 1)
      this.curriculum = JSON.parse(JSON.stringify(newCurri))
      // this.$emit('updateCurriculum', newCurri)
      if (deletingAnswer.id) this.deletePageMeta(deletingAnswer.id)
    },
    addMainPoint() {
      this.curriculum.main_points.push({ content: 'Main point here' })
      this.showSuccess('A new Main Point and Set have been added.')
    },
    addParagraph() {
      const length = this.curriculum.sets.length + 1
      const { sets } = this.curriculum
      sets.push({
        question: {
          content: `Set ${length} Question here`,
          image: '',
          audio: '',
          vocabulary: {
            word_in_eng: 'Question',
            synonym_1: 'sequence',
            synonym_2: 'arrangement',
            image: '',
            audio: '',
          },
        },
        answers: [
          {
            content: `Set ${length} Answer 1 here`,
            image: '',
            audio: '',
            vocabulary: {
              word_in_eng: 'Answer 1',
              synonym_1: 'sequence',
              synonym_2: 'arrangement',
              image: '',
              audio: '',
            },
          },
        ],
        wrongAnswers: [],
        paragraph: {
          content: '**This is correct statement**. [This is wrong statement].',
          images: [],
        },
        selectedStandard: SET_STANDARD[`set_${sets.length + 1}`] ?? [],
      })
      this.curriculum = { ...this.curriculum, sets }
      this.showSuccess('A new Paragraph have been added.')
      setTimeout(() => { this.$nextTick(() => this.$refs.setTab.updateTabs()) }, 100)
    },
    removeImage(type) {
      if (this.curriculum.images[type].id) this.deletePageMeta(this.curriculum.images[type].id)
      this.curriculum.images[type].content = null
    },
    submitData(generateProblem, type = '') {
      this.$forceUpdate()
      if (generateProblem) this.processingMethod = 'update-generate'
      else this.processingMethod = 'update'
      const data = new FormData()
      data.append('page_id', this.pageId)
      data.append('page_title', this.curriculum.page_title)
      data.append('thesis_statement', this.curriculum.thesis_statement)
      data.append('general_statement', this.curriculum.general_statement)
      data.append(
        'main_point',
        JSON.stringify(
          this.curriculum.main_points.length > 0
            ? this.curriculum.main_points.map(el => ({ content: el.content, id: el.id }))
            : [],
        ),
      )
      data.append(
        'sets',
        JSON.stringify(
          this.curriculum.sets.length > 0
            ? this.curriculum.sets.map(el => el)
            : [],
        ),
      )

      if (
        this.curriculum.images.question_radius_image.content
        !== this.originalCurriculum.images.question_radius_image.content
      ) {
        data.append(
          'question_radius_image',
          this.curriculum.images.question_radius_image.content,
        )
      }
      if (this.curriculum.images.top_image.content !== this.originalCurriculum.images.top_image.content) {
        data.append('top_image', this.curriculum.images.top_image.content)
      }
      if (
        this.curriculum.images.question_bg_image.content
        !== this.originalCurriculum.images.question_bg_image.content
      ) {
        data.append('question_bg_image', this.curriculum.images.question_bg_image.content)
      }
      if (this.curriculum.images.vocab_bg_image.content !== this.originalCurriculum.images.vocab_bg_image.content) {
        data.append('vocab_bg_image', this.curriculum.images.vocab_bg_image.content)
      }
      data.append('extra_question', this.curriculum.extra_question)
      data.append('review_questions', this.curriculum.review_questions)
      data.append('bottom_question', this.curriculum.bottom_question)
      useJwt
        .updateCurriculumPages(data)
        .then(response => {
          if (type === 'automate-audio') {
            this.automateAudio()
            return
          }
          if (type === 'automate-image') {
            this.automateImage()
            return
          }
          this.showSuccessMessage(response)
          if (generateProblem) this.generateProblems(type === 'vocab')
          else this.$emit('updated')
        })
        .finally(() => {
          if (!generateProblem && type !== 'automate-audio') this.processingMethod = false
        }).catch(error => {
          this.showErrorMessage(error)
        })
    },
    generateProblems(isVocab = false) {
      this.processingMethod = 'update-generate'
      useJwt.generateProblemsForCurriculum({ page_id: this.pageId, selectedProblems: this.selectedProblems, is_vocab: isVocab ? true : null }).then(res => {
        this.showSuccessMessage(res)
        this.$emit('updated')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.processingMethod = false
      })
    },
    removeMainPoint(index) {
      const mainPointBeingDeleted = this.curriculum.main_points[index]
      this.curriculum.main_points.splice(index, 1)
      if (mainPointBeingDeleted?.id) this.deletePageMeta(mainPointBeingDeleted.id)
    },
    removeSets(index) {
      if (this.curriculum.sets[index].set_no) {
        useJwt
          .deleteCurriculumPageSet({
            set_no: this.curriculum.sets[index].set_no,
            page_id: this.pageId,
          })
          .then(() => {
            this.curriculum.sets.splice(index, 1)
          })
      } else this.curriculum.sets.splice(index, 1)
    },
    getMainText() {
      let mainText = `${this.curriculum.review_questions} ${this.curriculum.extra_question} ${this.curriculum.bottom_question}`
      this.curriculum.sets.forEach(set => {
        mainText = `${mainText} ${set.question.content} ${set.paragraph.content} `
        set.answers.forEach(answer => {
          mainText = `${mainText} ${answer.content}`
        })
      })
      return mainText
    },
    getProblemType(set) {
      const problemType = this.autoGeneratedProblems.filter(ag => ag.key.includes(`-S${set}`) && ag.problem)
      const filterProblemType = problemType.map(element => ({
        id: element.problem?.id,
        type: element.problem?.problemType.name || '',
        lesson_id: element.problem?.lesson_id,
      }))
      return filterProblemType
    },
    extractMatches(index) {
      const value = this.curriculum.sets[index].paragraph.content
      const regExp = /\*\*(.*?)\*\*/g
      const matches = value.match(regExp)
      return matches
    },
    async checkRegex(index) {
      const matches = this.extractMatches(index)
      const words = []
      // this.curriculum.sets[index].paragraph.images = []
      if (matches) {
        const oldWords = this.curriculum.sets[index].paragraph.images
        // eslint-disable-next-line no-restricted-syntax
        for (const match of matches) {
          const word = match.substring(2, match.length - 2)
          // eslint-disable-next-line no-await-in-loop
          // await words.push({ word, image: '' })
          const oldWord = oldWords?.find(arr => arr.word === word)
          words.push({ ...oldWord, word })
          // if (!oldWords?.some(arr => arr.word === word)) {
          //   words.push({ word, image: '' })
          // }
        }
        if (words.length > 0) {
          this.isProcessingWord = true
          useJwt.saveWordsForParagraph(this.pageId, { words, set_no: this.activeSet + 1 }).then(res => {
            this.curriculum.sets[index].paragraph.images = res.data.data.words
            this.mapWords(index, res.data.data.words)
            this.orderWords(index)
            this.$forceUpdate()
          }).catch(err => {
            console.error(err)
          }).finally(() => {
            this.isProcessingWord = false
          })
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    orderWords(index) {
      const matches = this.extractMatches(index)
      if (matches) {
        const { temporaryImages } = this.curriculum.sets[index].paragraph
        this.curriculum.sets[index].paragraph.images = []
        // eslint-disable-next-line no-restricted-syntax
        for (const match of matches) {
          const word = match.substring(2, match.length - 2)
          this.curriculum.sets[index].paragraph.images.push({
            word, image: temporaryImages[word]?.image, id: temporaryImages[word]?.id, audio: temporaryImages[word]?.audio, incorrect_words: temporaryImages[word]?.incorrect_words,
          })
        }
      }
    },
    updateIncorrectWordsForParagraph(item) {
      useJwt.updateIncorrectWordsForParagraph(item.id, {
        incorrect_words: item.incorrect_words,
      }).then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    removeWords(k, i, wordId) {
      this.wordProcessing = true
      // this.curriculum.sets[k].paragraph.images.splice(i, 1)
      useJwt.deleteWordsForParagraph(parseInt(wordId, 10)).then(res => {
        this.curriculum.sets[k].paragraph.images = res.data.data.words
        this.mapWords(k, res.data.data.words)
        this.$forceUpdate()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.wordProcessing = false
      })
    },
    imageSelected(e, k, i, wordId, type = null) {
      if (type === 'picked') {
        useJwt.imageUploadForParagraphWords(wordId, { image: e }).then(res => {
          this.curriculum.sets[k].paragraph.images = res.data.data.words
          this.mapWords(k, res.data.data.words)
          this.$forceUpdate()
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.wordProcessing = false
        })
        return
      }
      if (!e.target.files[0]) return
      this.wordProcessing = true
      // eslint-disable-next-line prefer-destructuring
      this.fileToBase64(e.target.files[0]).then(result => {
        useJwt.imageUploadForParagraphWords(wordId, { image: result }).then(res => {
          this.curriculum.sets[k].paragraph.images = res.data.data.words
          this.mapWords(k, res.data.data.words)
          this.$forceUpdate()
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.wordProcessing = false
        })
      })
    },
    async  fileToBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          const base64String = reader.result
          resolve(base64String)
        }
      })
    },
    setStatementFeedBackAudio(object, type, { audio }) {
      this.fileToBase64(audio).then(result => {
        // eslint-disable-next-line no-param-reassign
        object[type] = result
      })
    },
    setAudioFromLibrary(setIndex, type, convertedAudio) {
      if (type === 'question') {
        this.curriculum.sets[setIndex].question.audio = convertedAudio
      } else if (type === 'vocabulary') {
        this.curriculum.sets[setIndex].question.vocabulary.audio = convertedAudio
      }
    },
    setAudioAudioFromLibrary(setIndex, answerIndex, type, convertedAudio) {
      if (type === 'answer') {
        this.curriculum.sets[setIndex].answers[answerIndex].audio = convertedAudio
      } else if (type === 'vocabulary') {
        this.curriculum.sets[setIndex].answers[answerIndex].vocabulary.audio = convertedAudio
      }
    },
    setQuestionAudio(setIndex, type, { audio, base64String }) {
      let convertedAudio = ''
      if (base64String) {
        if (type === 'question') {
          this.curriculum.sets[setIndex].question.audio = base64String
        } else if (type === 'vocabulary') {
          this.curriculum.sets[setIndex].question.vocabulary.audio = base64String
        }
      } else {
        this.fileToBase64(audio).then(result => {
          convertedAudio = result
          if (type === 'question') {
            this.curriculum.sets[setIndex].question.audio = convertedAudio
          } else if (type === 'vocabulary') {
            this.curriculum.sets[setIndex].question.vocabulary.audio = convertedAudio
          }
        })
      }
    },
    setAnswerAudio(setIndex, answerIndex, type, { audio, base64String }) {
      let convertedAudio = ''
      if (base64String) {
        if (type === 'answer') {
          this.curriculum.sets[setIndex].answers[answerIndex].audio = base64String
        } else if (type === 'vocabulary') {
          this.curriculum.sets[setIndex].answers[answerIndex].vocabulary.audio = base64String
        }
      } else {
        this.fileToBase64(audio).then(result => {
          convertedAudio = result
          if (type === 'answer') {
            this.curriculum.sets[setIndex].answers[answerIndex].audio = convertedAudio
          } else if (type === 'vocabulary') {
            this.curriculum.sets[setIndex].answers[answerIndex].vocabulary.audio = convertedAudio
          }
        })
      }
    },
    removeImageMeta(image) {
      if (image.image_id) {
        useJwt.deletePageMeta(image.image_id).then(response => {
          this.showSuccessMessage(response)
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
      // eslint-disable-next-line no-param-reassign
      image.image = null
    },
    setWordSelectorAudio(word, { audio }, type = null) {
      if (type === 'picked') {
        useJwt.imageUploadForParagraphWords(word.id, { audio }).then(() => {
        }).catch(err => {
          console.log(err)
        })
        return
      }
      this.fileToBase64(audio).then(result => {
        // eslint-disable-next-line no-param-reassign
        word.audio = result
        useJwt.imageUploadForParagraphWords(word.id, { audio: result }).then(() => {
          this.$forceUpdate()
        }).catch(err => {
          console.log(err)
        })
      })
    },
  },
}
</script>
<style>
.justify-content-right{
  justify-content: right !important;
}
.word-remove {
  position: absolute;cursor: pointer;right: 0;margin-right: 10px;
}
</style>
