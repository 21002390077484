<template>
  <b-modal :visible="true"
           title="Manage Assets"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <div class="row px-2">
      <div class="col-12">
        <h4>Cb Word Generation</h4>
        <SyncProgress
          title="Word Generation"
          :is-synching="state.isWordGenerationProcessing"
          btn-class="btn-block"
          :queue-key="`${props.pageInfo.id}_cbBulkWordGenAssets`"
          @onSync="handleBulkAssetUpdate('word_gen')"
        />
      </div>
      <div class="col-12 mt-2">
        <h4>Audio</h4>
        <SyncProgress
          title="Regenerate Audio"
          :is-synching="state.isAudioProcessing"
          btn-class="btn-block"
          :queue-key="`${props.pageInfo.id}_cbBulkAudioAssets`"
          @onSync="handleBulkAssetUpdate('audio')"
        />
      </div>
      <div class="col-12 mt-2">
        <h4>Image <small>
          <feather-icon icon="InfoIcon" /> Image generation might still continue even after the process completes.</small></h4>
        <SyncProgress
          title="Regenerate Images"
          btn-class="btn-block"
          :is-synching="state.isImageProcessing"
          :queue-key="`${props.pageInfo.id}_cbBulkImageAssets`"
          @onSync="handleBulkAssetUpdate('image')"
        />
      </div>
    </div>
  </b-modal>
</template>
<script setup>
import { BModal } from 'bootstrap-vue'
import SyncProgress from '@/views/super/image-library/components/SyncProgress.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getCurrentInstance, reactive } from 'vue'

const props = defineProps({
  pageInfo: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy.$root
const state = reactive({
  isAudioProcessing: false,
  isImageProcessing: false,
  isWordGenerationProcessing: false,
})

const processorName = type => {
  switch (type) {
    case 'audio':
      return 'isAudioProcessing'
    case 'image':
      return 'isImageProcessing'
    case 'word_gen':
      return 'isWordGenerationProcessing'
    default:
      return null
  }
}

const handleBulkAssetUpdate = (type = 'audio') => {
  const processor = processorName(type)
  state[processor] = true
  useJwt.bulkCbAssets(props.pageInfo.id, {
    type,
  }).then(response => {
    root.showSuccessMessage(response)
  }).catch(error => {
    state[processor] = false
    root.showErrorMessage(error)
  })
}
</script>
